<template>
  <v-card elevation="0">
    <v-toolbar height="80" elevation="0">
      <div class="font-size-h3 text-primary">System Settings</div>
    </v-toolbar>

    <perfect-scrollbar
      class="scroll"
      style="max-height:calc(100vh - 80px); position: relative"
    >
      <v-list>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="item in settings" :key="item.id">
            <v-list-item-icon>
              <unicon :name="item.icon" :fill="'#0971CE'"></unicon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </perfect-scrollbar>
  </v-card>
</template>

<script>
export default {
  components: {},
  name: "SettingsList",
  data() {
    return {
      loading: true,
      dialogDelete: false,
      selectedItem: 0,
      settings: [
        { id: 1, text: "System Settings", value: "system", icon: "setting" },
        { id: 2, text: "Departments", value: "departments", icon: "hospital" },
        { id: 3, text: "Vital Types", value: "vital", icon: "heart-rate" },
        { id: 4, text: "History Types", value: "history", icon: "band-aid" },
        {
          id: 5,
          text: "Payment Methods",
          value: "payment",
          icon: "transaction"
        },
        {
          id: 6,
          text: "Languages & Translations",
          value: "lang",
          icon: "english-to-chinese"
        }
      ]
    };
  },

  created() {},
  watch: {
    selectedItem(newselection) {
      // console.log("selecteditem is",newselection )
      this.$root.admin.selectedSetting = this.settings[newselection].value;
    }
  },
  methods: {},
  mounted() {}
};
</script>

<style></style>
